$(document).ready(function () {
    $(".copy-button").each(function () {
        $(this)
            .kendoTooltip({
                filter: ".icon-copy",
                autoHide: true,
                position: "right",
                animation: {
                    open: {
                        effects: "fade:in",
                        duration: 150,
                    },
                },
                show: function (e) {
                    e.sender.popup.element.addClass(
                        "tw-tooltip tw-tooltip--default",
                    );
                },
            })
            .data("kendoTooltip");
    });
    $(document).on("click", ".copy-button", function () {
        console.log("clicked");
        var checkIcon = $(this).find(".icon-check");
        var copyIcon = $(this).find(".icon-copy");
        console.log("aaa", checkIcon, copyIcon);
        copyIcon.hide();
        checkIcon.show();
        // tooltip.show(checkIcon);
        setTimeout(() => {
            checkIcon.hide();
            copyIcon.show();
        }, 3000);
        var textValue = $(this).attr("data-text");
        var $temp = $("<input name='copy'>");
        $("body").append($temp);
        $temp.val(textValue).select();
        // Copy the text to the clipboard
        document.execCommand("copy");
        $temp.remove();
    });
});
