$(document).ready(function () {
    const dialogTrigger = $(".tw-drawer-trigger");
    const closeDialog = $(".tw-drawer-close");
    $(".tw-drawer-content").each(function () {
        var windowId = $(this).attr("id");
        var title = $(this).data("title");
        var width = $(this).data("width");
        $("#" + windowId).kendoWindow(defaultWindowSlideFormat(title, width));
    });

    dialogTrigger.on("click", function (e) {
        e.preventDefault();
        let drawer = $(this).data("drawer");
        console.log("drawer", drawer);
        let kendoWindow = $(document).find("#" + drawer);
        console.log("window", kendoWindow);
        kendoWindow.getKendoWindow().open();
    });

    closeDialog.on("click", function (e) {
        e.preventDefault();
        var windowId = $(this).closest(".tw-drawer-content").attr("id");
        console.log("window", windowId);
        closeKendoWindow("#" + windowId);
    });
});
