$(document).ready(function () {
    const dialogTrigger = $(".tw-modal-trigger");
    const closeDialog = $(".tw-modal-close");
    $(".tw-modal-content").each(function () {
        var windowId = $(this).attr("id");
        var title = $(this).data("title");
        var width = $(this).data("width");
        var topOffset = $(this).data("top");
        var leftOffset = $(this).data("left");
        $("#" + windowId).kendoWindow(
            openCenterWindow(title, width, topOffset, leftOffset),
        );
        addModalClassToWindows(["#" + windowId]);
    });

    dialogTrigger.on("click", function (e) {
        e.preventDefault();
        let modal = $(this).data("modal");
        console.log("modal", modal);
        let kendoWindow = $(document).find("#" + modal);
        console.log("window", kendoWindow);
        kendoWindow.getKendoWindow().open();
    });

    closeDialog.on("click", function (e) {
        e.preventDefault();
        var windowId = $(this).closest(".tw-modal-content").attr("id");
        console.log("window", windowId);
        closeKendoWindow("#" + windowId);
    });
});
